import { hideLoadingDialog, showLoadingDialog } from './dialogs.js';
import { PROFILE_DATA_JSON, CURRENT_PAGE, decodeJWT, getCookie, setCookie, CONSOLE_MODE } from './module.js';

var websiteName = 'BibleGPT-LA';
var websiteSubtitle = 'Deepen Your Understanding';
var navLinks;
const verifiedPages = ["sign-in.html", "sign-up.html", "forget-password.html"];
const legalPages = ['privacy-policy.html', 'terms-of-use.html', 'refund-policy.html', "sign-in.html", "sign-up.html", "forget-pw.html", "profile.html"];

const main = document.querySelector(".main");
const asideNavbar = document.getElementById("side-navbar");


document.addEventListener("DOMContentLoaded", function() {
  document.addEventListener('touchstart', function(event) {
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        document.body.style.zoom = 'reset';
    }
  }, true);

  if(legalPages.includes(CURRENT_PAGE)){
    // { href: "../subscription.html", icon: "sack-dollar", text: "Subscription" },
    navLinks = [
      { href: "../", icon: "house-blank", text: "Home" },
      { href: "../chat.html", icon: "comments", text: "Chat" },
      { href: "../bible.html", icon: "bible", text: "Bible" },
      { href: "../about.html", icon: "info", text: "About us" }
    ];
  } else {
    // { href: "subscription.html", icon: "sack-dollar", text: "Subscription" },
    navLinks = [
      { href: ".", icon: "house-blank", text: "Home" },
      { href: "chat.html", icon: "comments", text: "Chat" },
      { href: "bible.html", icon: "bible", text: "Bible" },
      { href: "about.html", icon: "info", text: "About us" }
    ];
  }

  if (!verifiedPages.includes(CURRENT_PAGE)) {
    createAsideNavbar(navLinks, PROFILE_DATA_JSON).then((result) => {
      //if (CONSOLE_MODE) console.log('Promise resolved with data:', result);
      // Check if result is an HTML element
      const menuSwitch =  result instanceof HTMLElement ?
                          result.querySelector("#menu-switch") :
                          result.getElementById("menu-switch");
    
      // Add event listener to menu switch
      if (menuSwitch) {
          menuSwitch.addEventListener("click", function(){
            toggleAsideSidebar(menuSwitch);
          });
      }
    
      // Check if sidebar state is saved in localStorage
      const savedSidebarState = localStorage.getItem("sidebarState");
    
      // If a saved state exists, apply it to the sidebar
      if (savedSidebarState === "short") {
          toggleAsideSidebar(menuSwitch);
      }
    
      // For mobile screen
      const menuBtn = result instanceof HTMLElement ?
                      result.querySelector("#menuBtn") :
                      result.getElementById("menuBtn");
      const mobNavbar = result instanceof HTMLElement ?
                        result.querySelector("#mo-navbar") :
                        result.getElementById("mo-navbar");
      const historyBtn = result instanceof HTMLElement ?
                        result.querySelector("#historyBtn") :
                        result.getElementById("historyBtn");
    
      let isMobNavbarVisible = false;
      let isHistoryContentVisible = false;
    
      if(menuBtn){
        menuBtn.addEventListener('click', function () {
          isMobNavbarVisible = !isMobNavbarVisible;
          if (isMobNavbarVisible) {
              mobNavbar.style.transform = 'translateX(0%)'; // Show the mobNavbar
          } else {
              mobNavbar.style.transform = 'translateX(-100%)'; // Hide the mobNavbar
          }
          menuBtn.classList.toggle('close');
        });
      }

      if(historyBtn){
        const historyContainer = document.getElementById("chat-history");

        historyBtn.addEventListener('click', function () {
          isHistoryContentVisible = !isHistoryContentVisible;
          if (isHistoryContentVisible) {
            historyBtn.innerHTML = `<i class="fi fi-sr-circle-xmark"></i>`;
            historyContainer.style.transform = 'translateX(0%)'; // Show the historyContainer
          } else {
            historyBtn.innerHTML = `<i class="fi fi-rr-time-past"></i>`;
            historyContainer.style.transform = 'translateX(-100%)'; // Hide the historyContainer
          }
        });
      }
    
      // Call the function to create the footer
      createFooter();
    
    }).catch((error) => {
        console.error('Error:', error);
    });

    const excludeCookiesConsentPages = ["faq.html", "privacy-policy.html", "terms-of-use.html", "refund-policy.html"];
    if (!excludeCookiesConsentPages.includes(CURRENT_PAGE)){
      createCookiesPermissionPopup();
    }
  }
});

function createAsideNavbar(links, PROFILE_DATA_JSON) {
    return new Promise((resolve, reject) => {
      const moNavbar = document.createElement("div");
      moNavbar.id = "mo-navbar";
      moNavbar.className = "nav-menu";
    
      // Top Header
      const topHeader = document.createElement("div");
      topHeader.className = "top-header";
    
      const navTitle = document.createElement("div");
      navTitle.className = "nav-title nav-short-inactive";
    
      const webName = document.createElement("h2");
      webName.className = "web-name";
      webName.textContent = websiteName;
      navTitle.appendChild(webName);
    
      // const webSubtitle = document.createElement("p");
      // webSubtitle.className = "web-subtitle";
      // webSubtitle.textContent = websiteSubtitle;
      // navTitle.appendChild(webSubtitle);
    
      const menuSwitch = document.createElement("button");
      menuSwitch.id = "menu-switch";
      menuSwitch.className = "menu-switch-btn";
      menuSwitch.innerHTML = "<i class='bx bx-menu-alt-right'></i>";

      topHeader.appendChild(navTitle);
      topHeader.appendChild(menuSwitch);

      // Menu Button
      const menuBtn = document.createElement("button");
      menuBtn.id = "menuBtn";
      menuBtn.className = "menu";
      menuBtn.innerHTML = "<div class='bar'></div><div class='bar'></div><div class='bar'></div>";

      if(CURRENT_PAGE === "chat.html"){
        const historyBtn = document.createElement("button");
        historyBtn.id = "historyBtn";
        historyBtn.className = "menu history-btn";
        historyBtn.innerHTML = `<i class="fi fi-rr-time-past"></i>`;
        topHeader.appendChild(historyBtn);
      }
      
      topHeader.appendChild(menuBtn);
      
      // Profile
      const navProfile = document.createElement("div");
      navProfile.className = "nav-profile";
    
      const webLogo = document.createElement("div");
      webLogo.className = "web-logo";
      const logoImg = document.createElement("img");
      if(PROFILE_DATA_JSON.hasOwnProperty('profileURL') && PROFILE_DATA_JSON.profileURL != 0){
        logoImg.src = PROFILE_DATA_JSON.profileURL;
      } else {
        logoImg.src = "https://host.biblegpt-la.com/public_file/user_logo.png";
      }
      
      logoImg.alt = "User profile icon";
      webLogo.appendChild(logoImg);
    
      const userNameLabel = document.createElement("h2");
      userNameLabel.id = "user-name-label";
      userNameLabel.className = "nav-short-inactive";
      if(PROFILE_DATA_JSON.hasOwnProperty('userName') && PROFILE_DATA_JSON.userName != 0){
        userNameLabel.textContent = "Hi, " + PROFILE_DATA_JSON.userName.split(' ')[0];
      } else {
        userNameLabel.textContent = "Hi, User";
      }
    
      const viewProfileBtn = document.createElement("a");
      if(legalPages.includes(CURRENT_PAGE)){
        viewProfileBtn.href = "../account/profile.html";
      } else {
        viewProfileBtn.href = "account/profile.html";
      }
      viewProfileBtn.className = "view-profile-btn";
      viewProfileBtn.innerHTML = "<i class='bx bxs-user-account'></i><p>View<span class='nav-short-inactive'>Profile</span></p>";
    
      navProfile.appendChild(webLogo);
      navProfile.appendChild(userNameLabel);
      navProfile.appendChild(viewProfileBtn);
    
      // Navigation Links
      const navDiv = document.createElement("div");
      navDiv.className = "nav-div";
      navDiv.id = "nav-div";

      // Normalize the CURRENT_PAGE to handle index.html as root path
      const normalizedCurrentPage = (CURRENT_PAGE === "index.html" || CURRENT_PAGE === "") ? "." : CURRENT_PAGE;

      links.forEach(link => {
          const div = document.createElement("div");
          const a = document.createElement("a");
          a.href = link.href;

          if (link.href === normalizedCurrentPage) {
              // a.classList.add("nav-active");
              // a.innerHTML = `<i class='bx bxs-${link.icon}'></i><span class='nav-short-inactive'>${link.text}</span>`;
              a.innerHTML = `<i class="fi fi-sr-${link.icon}"></i><span class='nav-short-inactive'>${link.text}</span>`;
              div.classList.add("nav-div-item", "active");
          } else {
              a.innerHTML = `<i class="fi fi-rr-${link.icon}"></i><span class='nav-short-inactive'>${link.text}</span>`;
              div.classList.add("nav-div-item");
          }
          div.appendChild(a);
          navDiv.appendChild(div);
      });
    
      // Bottom Links
      const navBottom = document.createElement("div");
      navBottom.className = "nav-bottom";
    
      const playStoreBtn = document.createElement("a");
      playStoreBtn.href = "https://play.google.com/store/apps/details?id=com.ai.bibleGPT";
      playStoreBtn.className = "play-store-btn";
      playStoreBtn.id = "play-store-btn";
      playStoreBtn.innerHTML = "<i class='bx bxl-play-store'></i><div class='play-store-txt nav-short-inactive'><p>Get it on</p><h3>Google Play</h3></div>";
    
      const appStoreBtn = document.createElement("a");
      appStoreBtn.href = "https://apps.apple.com/us/app/la-biblegpt/id6451411288";
      appStoreBtn.className = "app-store-btn";
      appStoreBtn.id = "app-store-btn";
      appStoreBtn.innerHTML = "<i class='bx bxl-apple'></i><div class='play-store-txt nav-short-inactive'><p>Available on</p><h3>App Store</h3></div>";
    
      navBottom.appendChild(playStoreBtn);
      navBottom.appendChild(appStoreBtn);
    
      // Assemble navbar
      moNavbar.appendChild(navProfile);
      moNavbar.appendChild(navDiv);
      moNavbar.appendChild(navBottom);
      
      if(asideNavbar){
        asideNavbar.appendChild(topHeader);
        asideNavbar.appendChild(moNavbar);
        resolve(asideNavbar);
      }
    })
}

// Function to toggle sidebar state
function toggleAsideSidebar(menuSwitch) {
  main.classList.toggle("short-main");
  asideNavbar.classList.toggle("short-navbar");
  var iconElement = menuSwitch.querySelector("i");
  iconElement.classList.toggle("bx-menu-alt-right");
  iconElement.classList.toggle("bx-menu-alt-left");
  // Select all elements with the class "nav-short-inactive"
  var navElements = document.querySelectorAll(".nav-short-inactive");

  // Loop through each element and toggle the "hidden" class
  navElements.forEach(function(navElement) {
    navElement.classList.toggle("hidden");
  });

  // Save the sidebar state in localStorage
  const sidebarState = asideNavbar.classList.contains("short-navbar") ? "short" : "full";
  localStorage.setItem("sidebarState", sidebarState);
}

const excuteCookiesBox = () => {
    const cookieValue = getCookie("CookieConsentForBibleGPT");
    const cookiesBox = document.querySelector(".cookies-permission");
    const buttons = document.querySelectorAll(".ck-btn");

    if (cookieValue !== "website"){
      cookiesBox.classList.add("show");

      buttons.forEach(button => {
          button.addEventListener('click', () =>{
              cookiesBox.classList.remove("show");
  
              if(button.id == "acceptBtn"){
                  // Set cookies for 1 months
                  setCookie("CookieConsentForBibleGPT", "website", 30);
              }
          });
      });
    } 
}      

window.addEventListener('load', excuteCookiesBox);

function createCookiesPermissionPopup(){
  // Create the cookies permission div
  var cookiesPermissionDiv = document.createElement('div');
  cookiesPermissionDiv.classList.add('cookies-permission');

  // Create the cookies div
  var cookiesDiv = document.createElement('div');
  cookiesDiv.classList.add('cookies-div');

  // Create the cookie icon
  var cookieIcon = document.createElement('i');
  cookieIcon.classList.add('bx', 'bxs-cookie');

  // Create the app content div
  var appContentDiv = document.createElement('div');
  appContentDiv.classList.add('app-content');

  // Create the cookies consent heading
  var cookiesConsentHeading = document.createElement('h1');
  cookiesConsentHeading.textContent = 'Cookies consent';

  // Create the cookies consent paragraph
  var cookiesConsentParagraph = document.createElement('p');
  cookiesConsentParagraph.textContent = 'The website uses cookies to help you have a better and more relevant browsing experience on the website. ';
  var knowMoreLink = document.createElement('a');
  knowMoreLink.href = 'legal/privacy-policy.html';
  knowMoreLink.target = '_blank';
  knowMoreLink.textContent = 'Know more';
  cookiesConsentParagraph.appendChild(knowMoreLink);

  // Append the heading and paragraph to the app content div
  appContentDiv.appendChild(cookiesConsentHeading);
  appContentDiv.appendChild(cookiesConsentParagraph);

  // Create the app content button div
  var appContentBtnDiv = document.createElement('div');
  appContentBtnDiv.classList.add('app-content-btn');

  // Create the "Not now" button
  var notNowButton = document.createElement('button');
  notNowButton.classList.add('a-btn-outline', 'ck-btn');
  notNowButton.textContent = 'Not now';

  // Create the "Accept" button
  var acceptButton = document.createElement('button');
  acceptButton.id = 'acceptBtn';
  acceptButton.classList.add('btn', 'ck-btn');
  acceptButton.textContent = 'Accept';

  // Append the buttons to the app content button div
  appContentBtnDiv.appendChild(notNowButton);
  appContentBtnDiv.appendChild(acceptButton);

  // Append the cookie icon, app content div, and app content button div to the cookies div
  cookiesDiv.appendChild(cookieIcon);
  cookiesDiv.appendChild(appContentDiv);
  cookiesDiv.appendChild(appContentBtnDiv);

  // Append the cookies div to the cookies permission div
  cookiesPermissionDiv.appendChild(cookiesDiv);

  // Add the cookies permission div to the document body or any other desired container
  document.body.appendChild(cookiesPermissionDiv);
}


function createFooter() {
  let links;
  if(legalPages.includes(CURRENT_PAGE)){
    links = [
      { href: "../about.html", icon: "bx bx-chevron-right", text: "About" },
      { href: "../contact.html", icon: "bx bx-chevron-right", text: "Contact us" },
      { href: "privacy-policy.html", icon: "bx bx-chevron-right", text: "Privacy policy" },
      { href: "terms-of-use.html", icon: "bx bx-chevron-right", text: "Terms & Conditions" },
      { href: "refund-policy.html", icon: "bx bx-chevron-right", text: "Refund & Cancellation policy" }
    ];
  } else {
    links = [
      { href: "about.html", icon: "bx bx-chevron-right", text: "About" },
      { href: "contact.html", icon: "bx bx-chevron-right", text: "Contact us" },
      { href: "legal/privacy-policy.html", icon: "bx bx-chevron-right", text: "Privacy policy" },
      { href: "legal/terms-of-use.html", icon: "bx bx-chevron-right", text: "Terms & Conditions" },
      { href: "legal/refund-policy.html", icon: "bx bx-chevron-right", text: "Refund & Cancellation policy" }
    ];
  }
  // Left container
  const leftCon = document.createElement('div');
  leftCon.className = 'left-con';

  // Logo div
  const logoDiv = document.createElement('div');
  logoDiv.className = 'logo-div';

  // Web name heading
  const webNameHeading = document.createElement('h2');
  webNameHeading.className = 'web-name';
  webNameHeading.textContent = 'BibleGPT-LA';

  // Mount Olive Academy heading
  const moaHeading = document.createElement('h1');
  moaHeading.textContent = 'Developed by Mount Olive Academy';

  // Description paragraph
  const description = document.createElement('p');
  description.textContent = 'BibleGPT-LA is an AI-powered tool designed to provide accurate, Bible-based answers to user queries. This platform enhances Bible study, theology, and Christian living through a seamless chat interface or search box.';

  // Copyright paragraph
  const copyright = document.createElement('p');
  copyright.className = 'copyright-txt';
  copyright.textContent = '© 2023-2024 BibleGPT-LA, All rights reserved by Mount Olive Academy.';

  // Append elements to logo div
  logoDiv.appendChild(webNameHeading);
  logoDiv.appendChild(moaHeading);
  logoDiv.appendChild(description);
  logoDiv.appendChild(copyright);

  // Append logo div to left container
  leftCon.appendChild(logoDiv);

  // Right container
  const rightCon = document.createElement('div');
  rightCon.className = 'right-con';

  // Generate links from the object array
  links.forEach(link => {
      const a = document.createElement('a');
      a.href = link.href;
      // a.setAttribute("data-aos", "slide-left");
      // a.setAttribute("data-aos-anchor-placement", "center-bottom");

      const icon = document.createElement('i');
      icon.className = link.icon;

      const span = document.createElement('span');
      span.textContent = link.text;

      a.appendChild(icon);
      a.appendChild(span);
      rightCon.appendChild(a);
  });

  // Footer element
  const footer = document.getElementById('footer');
  if(footer){
    // Append containers to footer
    footer.appendChild(leftCon);
    footer.appendChild(rightCon);
  }
}

const appStoreDialog = document.querySelector(".app-store-dialog"),
        appStorebuttons = document.querySelectorAll(".app-play-store-btn");

const excuteAppStoreDialog = () => {
    if (document.cookie.includes("PlayStoreDialog")) return;
    appStoreDialog.classList.add("show");

    appStorebuttons.forEach(appStorebutton => {
        appStorebutton.addEventListener('click', () =>{
            appStoreDialog.classList.remove("show");

            if(appStorebutton.id == "play-store-btn"){
                // Set cookies for 7 days
                document.cookie = "PlayStoreDialog= PlayStoreDialog; max-age=" + 60 * 60 * 24 * 7;
            }
        });
    });
} 

// Get the width of the viewport
var viewportWidth = window.innerWidth;

// Display the width in the console
//if (CONSOLE_MODE) console.log("Viewport width: " + viewportWidth + "px");

function updateViewportWidth() {
  var viewportWidth = window.innerWidth;
  //if (CONSOLE_MODE) console.log("Updated viewport width: " + viewportWidth + "px");
}

// Initial width
updateViewportWidth();

// Update width on resize
window.addEventListener('resize', updateViewportWidth);

function getViewportWidth() {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

var viewportWidth = getViewportWidth();
if (CONSOLE_MODE) console.log("Viewport width: " + viewportWidth + "px");

hideLoadingDialog()