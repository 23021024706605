// Show the loading dialog
export function showLoadingDialog() {
    const loadingDialog = document.getElementById("loading-dialog");
    loadingDialog.style.display = "flex";
}
  
// Hide the loading dialog
export function hideLoadingDialog() {
    const loadingDialog = document.getElementById("loading-dialog");
    loadingDialog.style.display = "none";
}

export function alertDialog(dialogId, closeIconIndex, btnIndex, btnText, btnLink, title, body, lottieFilePath, btnIndex2, btnText2, btnLink2){
    // Check if dialog already exists
    var existingDialog = document.getElementById(dialogId);
    if(existingDialog) {
        // existingDialog.classList.add('show');
        return; // Exit export function if dialog already exists
    }

    // Create dialog element
    var dialogElement = document.createElement('dialog');
    dialogElement.id = dialogId;
    dialogElement.classList.add('alert-dialog');
    const dialogIdVal = `#${dialogId}`;
    console.log("dialogIdVal:", dialogIdVal);
    dialogElement.innerHTML = `
    <div class="dialog-container">
        <div id="lottie-container" class="lottie-container"></div>
        <div class="dialog-title">
            <h2>${title}</h2>
            ${closeIconIndex == 1 ? `<button type="button" class="close-btn hide-btn"><i class='bx bx-x'></i></button>` : ``}
        </div>
        <div class="dialog-body">
            <p>${body}</p>
        </div>
        <div class="dialog-footer">
            ${btnIndex2 == 1 ? `<a href="${btnLink2}" class="a-btn-outline">${btnText2}</a>` : ``}
            ${btnIndex == 1 ? `<a href="${btnLink}" class="a-btn">${btnText}</a>` : `<a class="a-btn hide-btn">${btnText}</a>`}
        </div>
    </div>`;
    document.body.appendChild(dialogElement);
    dialogElement.classList.add('show');

    const closeButtons = dialogElement.querySelectorAll('.hide-btn');
    closeButtons.forEach(btn => {
        btn.addEventListener('click', function(){
            dialogElement.classList.remove('show');
            // Remove the dialog element from the DOM after it's closed
            dialogElement.addEventListener('transitionend', function() {
                dialogElement.remove();
            }, { once: true });

            // If there's no transition, remove immediately
            if (getComputedStyle(dialogElement).transitionDuration === '0s') {
                dialogElement.remove();
            }
        });
    });

    let lotiesfiles = "https://host.biblegpt-la.com/public_file/error.json";
    if(lottieFilePath == 200){
        lotiesfiles = "https://host.biblegpt-la.com/public_file/done.json"
    } else if(lottieFilePath == 300){
        lotiesfiles = "https://host.biblegpt-la.com/public_file/cross.json"
    } else {
        lotiesfiles = "https://host.biblegpt-la.com/public_file/error.json";
    }
    var animation = lottie.loadAnimation({
        container: document.getElementById('lottie-container'), // the DOM element to contain the animation
        renderer: 'svg', // render as SVG
        loop: true, // loop the animation
        autoplay: true, // start playing the animation immediately
        path: lotiesfiles // the path to the animation JSON file
    });
}
//alertDialog("alert-socket-error", 1, 1, "Try again", "chat.html", "Internal Error!", "Currently, we're experiencing difficulties connecting to our server. Please retry later or reach out to us for assistance.", 200, 1, "Go to home", "index.html");
        
export function closeAlertDialog(dialogId) {
    var dialog = document.querySelector(dialogId);
    if (dialog) {
        dialog.classList.remove('show');
        // Remove the dialog element from the DOM after it's closed
        dialog.addEventListener('transitionend', function() {
            dialog.remove();
        }, { once: true });
    }
}

// Function to create and append the dialog
export function createSmallPreloaderDialog(textContent, parentElements) {
    // Create dialog element
    var dialog = document.createElement('dialog');
    dialog.classList.add('small-preloader-dialog');
  
    // Create div inside dialog
    var div = document.createElement('div');
    div.classList.add('preloader-dialog-div');
  
    // Create loader icon
    var loaderIcon = document.createElement('i');
    loaderIcon.classList.add('bx', 'bx-loader-circle');
  
    // Create text
    var text = document.createElement('p');
    text.textContent = textContent;
  
    // Append loader icon and text to div
    div.appendChild(loaderIcon);
    div.appendChild(text);
  
    // Append div to dialog
    dialog.appendChild(div);
  
    if(parentElements !== 0){
      parentElements.appendChild(dialog);
    } else {
      // Append dialog to body
      document.body.appendChild(dialog);
    }
}
  
// Function to remove the dialog
export function removeDialog() {
    var dialog = document.querySelector('.small-preloader-dialog');
    if (dialog) {
        dialog.remove();
    }
}
  
//  Example usage
//  createSmallPreloaderDialog("Submitting....", 0);
//  setTimeout(removeDialog, 3000);